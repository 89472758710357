import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';
import ArrayUtils from '~/utils/arrayUtils';
import ObjectUtils from '~/utils/objectUtils';
import Log from '~/utils/Log';
import FunctionUtils from '~/utils/functionUtils';
import { LOADING_STATE } from '~/constants/LoadingState';
import ExportService from '~/services/export.service';
import { setPageTitle } from '~/redux/menuSlice';

import DataExchangeService from '~/services/dataExchange.service';
import { promiseHandler } from '~/utils/promiseHandler';
import ToastService from '~/services/toast.service';
import { setUpdatedSuppliers } from '~/redux/dataExchangesSlice';

const TEXTFIELD_ID = 'suppliers_textfield_id';
const QUERY_FUNCTION_ID = 'query_function_id';

export const useSuppliers = () => {
  const dataExchanges = useSelector((state) => state.dataExchanges);
  const allCompanies = useSelector((state) => state.companies.companies);
  const sortData = useSelector((state) => state.filters.suppliers_sort_model);
  const userCompanyAccount = useSelector(
    (state) => state.userinfo?.userinfo?.company,
  );
  const selectedCompanies = useSelector(
    (state) => state.filters.suppliers_selectedCompanies,
  );
  const selectedResponsiblePeople = useSelector(
    (state) => state.filters.suppliers_responsible_people,
  );

  const dispatch = useDispatch();
  const isMounted = useRef(false);

  const [searchValue, setSearchValue] = useState('');
  const [kanbanColumns, setKanbanColumns] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);

  const [showNewSupplierModal, setShowNewSupplierModal] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [isFilteringSupplier, setIsFilteringSupplier] = useState(
    LOADING_STATE.NOT_LOADED,
  );
  const [isCreatingNewDataExchange, setIsCreatingNewDataExchange] = useState(
    LOADING_STATE.NOT_LOADED,
  );

  const groupSuppliersByColumn = useCallback((data) => {
    const columns = ObjectUtils.entries(DataExchangeCompany.STATUS)
      .filter(
        (entry) =>
          entry.value.KEY !== DataExchangeCompany.STATUS.NO_STATUS.KEY &&
          entry.value.KEY !== DataExchangeCompany.STATUS.CURRENT_ISSUES.KEY,
      )
      .map((entry) => {
        const columnSuppliers = data.filter(
          (supplier) => supplier.statusDeliveryNote === entry.value.KEY,
        );

        return {
          active: entry.value.HIGHLIGHTED,
          description: entry.value.DESCRIPTION,
          key: entry.value.KEY,
          label: entry.value.LABEL,
          suppliers: columnSuppliers,
        };
      });

    setFilteredSuppliers(data);
    setKanbanColumns(columns);
  }, []);

  const createNewDataExchange = async (supplier) => {
    setIsCreatingNewDataExchange(LOADING_STATE.LOADING);

    ToastService.info(['Daten des Lieferanten werden geladen']);

    const body = {
      receiver_id: userCompanyAccount.id,
      sender_id: supplier?.id,
      is_exchange_via_paper: false,
    };

    const [response, error] = await promiseHandler(
      DataExchangeService.createNewDataExchange(body),
    );

    setIsCreatingNewDataExchange(LOADING_STATE.NOT_LOADED);

    if (error) {
      Log.error('Failed to create new data exchange', error);
      Log.productAnalyticsEvent(
        'Failed to create new data exchange',
        Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        Log.TYPE.ERROR,
      );
      ToastService.error([
        'Dieser Lieferant ist noch nicht freigeschalten. Bitte melde dich bei deiner VESTIGAS Ansprechperson',
      ]);

      return;
    }

    const updatedSupplier = {
      ...supplier,
      dataExchange: response,
    };

    setSelectedSupplier(updatedSupplier);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    Log.info(
      'Change value of search input',
      { from: searchValue, to: value },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change value of search input',
      Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
    );

    setSearchValue(value);

    FunctionUtils.delayFunction(
      TEXTFIELD_ID,
      () => {
        getFilteredDataExchanges(value);
        Log.productAnalyticsEvent(
          'Update search input value ',
          Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
        );
      },
      [value],
      1000,
    );
  };

  const handleSearchClear = () => {
    Log.info(
      'Change value of search input',
      { from: searchValue, to: '' },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Clear search value',
      Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
    );

    setSearchValue('');
    getFilteredDataExchanges('');
  };

  const exportExcel = () => {
    Log.info('Exporting to excel');
    Log.productAnalyticsEvent(
      'Exporting filtered data exchanges to excel',
      Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
    );

    const data = filteredSuppliers.map((supplier) => {
      const exchange = supplier.dataExchange;

      return [
        supplier?.name,
        supplier?.address?.getConcatenatedAddress?.(),
        DataExchangeCompany?.getStatusLabel(supplier.statusDeliveryNote),
        DataExchangeCompany?.getStatusLabel(
          exchange?.exchangeDeliveryNotes || supplier.statusDeliveryNote,
        ),
        DataExchangeCompany?.getStatusLabel(
          exchange?.exchangeInvoices || supplier.invoiceStatus,
        ),
        exchange?.activeCustomerIds?.length || 0,
        exchange?.knownIssues,
        exchange?.furtherInformation,
      ];
    });

    data.unshift([
      'Name',
      'Adresse',
      'Status',
      'Lieferungen',
      'Rechnungen',
      'Kundennummern',
      'Bekannte Probleme',
      'Weitere Informationen',
    ]);

    ExportService.exportExcel(data);
  };

  const getFilteredDataExchanges = useCallback(
    async (searchQuery = '', silent = false) => {
      if (!silent) {
        setIsFilteringSupplier(LOADING_STATE.LOADING);
      }

      const queryParams = {
        order_by: sortData.sortKey,
        sort_order: sortData.sortDirection,
        responsible_person_id: selectedResponsiblePeople,
        company_id: selectedCompanies,
        search_text: searchQuery,
      };

      const queryString =
        DataExchangeService.convertQueryParamsToQueryString(queryParams);

      const [response, error] = await promiseHandler(
        DataExchangeService.getFilteredSuppliers(queryString),
      );

      setIsFilteringSupplier(LOADING_STATE.SUCCEEDED);

      if (error) {
        Log.error('Failed to filter data exchanges', error);
        Log.productAnalyticsEvent(
          'Failed to filter data exchanges',
          Log.FEATURE.KANBAN_SUPPLIER_OVERVIEW,
          Log.TYPE.ERROR,
        );

        if (!silent) {
          ToastService.error(['Datenaustausch konnte nicht gefiltert werden']);
        }

        return;
      }

      groupSuppliersByColumn(response);
    },
    [
      groupSuppliersByColumn,
      selectedCompanies,
      selectedResponsiblePeople,
      sortData.sortDirection,
      sortData.sortKey,
    ],
  );

  const updateSupplier = (updatedSupplier) => {
    // const hasSelectedCompanies = Boolean(selectedCompanies?.length);
    // const hasSelectedResponsiblePeople = Boolean(selectedResponsiblePeople?.length);

    const updatedSuppliers = ArrayUtils.updateByKey(
      filteredSuppliers,
      'id',
      updatedSupplier.id,
      updatedSupplier,
    ).filter(() => {
      // const exchange = supplier.dataExchange;

      // if (searchValue !== '') {
      //   if (!supplier.name.includes(searchValue?.toLowerCase())) return false;
      // }

      // filter by selected companies
      // if (hasSelectedCompanies) {
      //   if (!selectedCompanies.includes(exchange?.receiverId)) return false;
      // }

      // // filter by selected responsible people
      // if (hasSelectedResponsiblePeople) {
      //   if (!selectedResponsiblePeople.includes(supplier?.responsiblePerson?.id)) return false;
      // }

      return true;
    });

    dispatch(setUpdatedSuppliers(updatedSuppliers));
  };

  const showSupplierDetailModal = Boolean(selectedSupplier);

  useEffect(() => {
    isMounted.current ||= true;
  }, [
    sortData.sortDirection,
    sortData.sortDirection,
    selectedResponsiblePeople.length,
    selectedCompanies.length,
  ]);

  useEffect(() => {
    FunctionUtils.delayFunction(
      QUERY_FUNCTION_ID,
      (value) => {
        if (
          isMounted.current &&
          dataExchanges.suppliersLoading === LOADING_STATE.SUCCEEDED
        ) {
          getFilteredDataExchanges(value);
        }
      },
      [searchValue],
      1000,
    );
  }, [
    sortData.sortDirection,
    sortData.sortDirection,
    selectedResponsiblePeople.length,
    selectedCompanies.length,
  ]);

  useEffect(() => {
    groupSuppliersByColumn(dataExchanges.suppliers);
  }, [dataExchanges.suppliersLoading, dataExchanges.suppliers]);

  useEffect(() => {
    dispatch(setPageTitle('Anbindungen'));
  }, []);

  useEffect(() => {
    DataExchangeService.loadSuppliers();
  }, []);

  useEffect(() => {
    DataExchangeService.loadSuppliers();
  }, []);

  return {
    allCompanies,
    createNewDataExchange,
    dataExchanges,
    exportExcel,
    filteredSuppliers,
    handleInputChange,
    handleSearchClear,
    isCreatingNewDataExchange,
    isFilteringSupplier,
    kanbanColumns,
    searchValue,
    selectedCompanies,
    selectedResponsiblePeople,
    selectedSupplier,
    setFilteredSuppliers,
    setIsCreatingNewDataExchange,
    setIsFilteringSupplier,
    setKanbanColumns,
    setSearchValue,
    setSelectedSupplier,
    setShowNewSupplierModal,
    showNewSupplierModal,
    showSupplierDetailModal,
    sortData,
    updateSupplier,
    userCompanyAccount,
  };
};
