import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import React, { createRef, memo } from 'react';
import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';

import AuthService from '~/services/auth.service';
import LocalStorageService from '~/services/localStorage.service';
import UserService from '~/services/user.service';

import { LOADING_STATE } from '~/constants/LoadingState';

import Log from '~/utils/Log';
import DatagridUtils from '~/utils/datagridUtils';
import ArrayUtils from '~/utils/arrayUtils';
import FunctionUtils from '~/utils/functionUtils';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

import { GridToolbar } from './interaction/GridToolbar';
import { Spinner } from './Spinner';

class BasicTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columnOrderModel: [],
      columnVisibilityModel: {},
      columnWidthModel: {},
      detailPanelExpandedRowIds: [], // Initialize detailPanelExpandedRowIds to an empty array
      filteredSortedRows: [],
      page: this.props.page ?? 0,
      pageSize: this.props.pageSize ?? 100,
      pinnedColumns: LocalStorageService.getObjectFromLocalStorage(
        this.props.pinnedColumnsCookieId,
      ) ??
        this.props.defaultPinnedColumns ?? { right: [], left: [] },
      rowHeight: DatagridUtils.ROW_HEIGHT.THIN,
    };

    this.apiRef = createRef();

    this.scrolledRowIndex = 0;
    this.debouncedUpdateUserSettings = debounce(this.updateUserSettings, 400);
  }

  componentDidMount() {
    const data = LocalStorageService.getObjectFromLocalStorage(
      this.props.localStorageKey,
    );

    const newState = {
      columnVisibilityModel: data?.columnVisibilityModel ?? {},
      columnWidthModel: data?.columnWidthModel ?? {},
      columnOrderModel: data?.columnOrderModel ?? [],
      rowHeight:
        data?.rowHeight ??
        this.props.defaultRowHeight ??
        DatagridUtils.ROW_HEIGHT.THIN,
    };

    newState.columnVisibilityModel = this.hideDefaultColumns(
      newState.columnVisibilityModel,
    );

    this.setState(newState);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(this.props.defaultHiddenColumns) !==
      JSON.stringify(prevProps.defaultHiddenColumns)
    ) {
      this.setState({
        columnVisibilityModel: this.hideDefaultColumns(
          this.state.columnVisibilityModel,
        ),
      });
    }

    if (
      this.props.triggerScrollToNewRows !== prevProps.triggerScrollToNewRows &&
      this.props.triggerScrollToNewRows
    ) {
      this.scrollToNewRows();
    }
  }

  hideDefaultColumns(columnVisibilityModel) {
    const newColumnVisibilityModel = cloneDeep(columnVisibilityModel);

    if (this.props.defaultHiddenColumns)
      for (const column of this.props.defaultHiddenColumns) {
        if (newColumnVisibilityModel[column] === undefined) {
          newColumnVisibilityModel[column] = false;
        }
      }

    return newColumnVisibilityModel;
  }

  async scrollToNewRows() {
    if (!this.props.scrollToNewRowsTimeout) {
      return;
    }

    await FunctionUtils.timer(this.props.scrollToNewRowsTimeout);

    if (!this.apiRef.current) {
      this.scrollToNewRows();
      return;
    }

    if (this.scrolledRowIndex === this.props.maxScrollToNewRowsIndex) {
      this.scrolledRowIndex = 0;
      this.props.onScrolledRowIndexChange(0);
      this.apiRef.current.scrollToIndexes({ rowIndex: 0 });
      this.scrollToNewRows();
      return;
    }

    let newRowIndex = this.scrolledRowIndex + this.props.scrollToNewRowsSize;
    if (newRowIndex >= this.props.maxScrollToNewRowsIndex) {
      newRowIndex = this.props.maxScrollToNewRowsIndex;
    }

    this.scrolledRowIndex = newRowIndex;
    this.props.onScrolledRowIndexChange(newRowIndex);
    this.apiRef.current.scrollToIndexes({ rowIndex: newRowIndex });
    this.scrollToNewRows();
  }

  onColumnVisibilityModelChange = (event) => {
    Log.info(
      'Change column visibility',
      { from: this.state.columnVisibilityModel, to: event },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent(
      'Change column visibility',
      Log.FEATURE.BASIC_TABLE,
    );

    this.setState({
      columnVisibilityModel: event,
    });

    this.storeLocally(
      event,
      this.state.columnWidthModel,
      this.state.columnOrderModel,
      this.state.rowHeight,
    );
  };
  onColumnWidthChange = (event) => {
    const newColumnWidthModel = cloneDeep(this.state.columnWidthModel);

    newColumnWidthModel[event.colDef.field] = event.colDef.width;

    Log.info(
      'Change column width',
      { from: this.state.columnWidthModel, to: newColumnWidthModel },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change column width', Log.FEATURE.BASIC_TABLE);

    this.setState({
      columnWidthModel: newColumnWidthModel,
    });

    this.storeLocally(
      this.state.columnVisibilityModel,
      newColumnWidthModel,
      this.state.columnOrderModel,
      this.state.rowHeight,
    );
  };
  onColumnOrderChange = async (newColumnOrderModel) => {
    const columnOrderModel = LocalStorageService.getObjectFromLocalStorage(
      this.props.localStorageKey,
    )?.columnOrderModel;

    if (
      newColumnOrderModel &&
      JSON.stringify(newColumnOrderModel) === JSON.stringify(columnOrderModel)
    ) {
      return;
    }

    // Needed to fix bug: If column has been added or removed in code, onColumnOrderChange is called before states are initialized.
    // Thus, without this fallback, the cookie would have been filled with empty states
    if (this.state.columnOrderModel.length === 0) {
      this.storeLocally(
        LocalStorageService.getObjectFromLocalStorage(
          this.props.localStorageKey,
        )?.columnVisibilityModel,
        LocalStorageService.getObjectFromLocalStorage(
          this.props.localStorageKey,
        )?.columnWidthModel,
        newColumnOrderModel,
        LocalStorageService.getObjectFromLocalStorage(
          this.props.localStorageKey,
        )?.rowHeight,
      );

      // set state to prevent bug:
      // When changing column order and changing column visibility before reloading, column order was reset.
      // Because no state was updated, still the old order was passed in columns prop to the data grid.
      this.setState({
        columnOrderModel: newColumnOrderModel,
      });

      return;
    }

    Log.info(
      'Change column order',
      { from: columnOrderModel, to: newColumnOrderModel },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change column order', Log.FEATURE.BASIC_TABLE);

    this.storeLocally(
      this.state.columnVisibilityModel,
      this.state.columnWidthModel,
      newColumnOrderModel,
      this.state.rowHeight,
    );

    // set state here also to prevent bug
    this.setState({
      columnOrderModel: newColumnOrderModel,
    });

    // Save table configuration to backend; debounced to prevent multiple calls and danger of race condition.
    this.debouncedUpdateUserSettings();
  };
  onRowHeightChange = (rowHeight) => {
    Log.info(
      'Change row height',
      {
        from: this.state.rowHeight,
        to: rowHeight,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change row height', Log.FEATURE.BASIC_TABLE);

    this.setState({
      rowHeight,
    });

    this.storeLocally(
      this.state.columnVisibilityModel,
      this.state.columnWidthModel,
      this.state.columnOrderModel,
      rowHeight,
    );
  };
  onStateChange = (state) => {
    this.onColumnOrderChange(state.columns.orderedFields);

    if (this.props.onStateChange) {
      this.props.onStateChange(state);
    }

    if (!this.props.excelData) {
      return;
    }

    const newFilteredSortedRows = Array.from(
      new Set(
        gridFilteredSortedRowIdsSelector(state, this.apiRef.current.state),
      ),
    );

    if (
      JSON.stringify(this.state.filteredSortedRows) ===
      JSON.stringify(newFilteredSortedRows)
    ) {
      return;
    }

    this.setState({
      filteredSortedRows: newFilteredSortedRows,
    });
  };
  onRowSelectionModelChange = (event) => {
    if (this.props.onlySingleSelection) {
      this.props.onRowSelectionModelChange(
        ArrayUtils.subtract(event, this.props.rowSelectionModel),
      );
      return;
    }

    return this.props.onRowSelectionModelChange(event);
  };
  onPageChange = (newPage) => {
    this.setState({
      page: newPage,
    });

    if (this.props.onPageChange) {
      this.props.onPageChange(newPage);
    }
  };
  onPinnedColumnsChange = (event) => {
    Log.info(
      'Change pinned columns',
      {
        from: this.state.pinnedColumns,
        to: event,
      },
      Log.BREADCRUMB.FILTER_CHANGE.KEY,
    );
    Log.productAnalyticsEvent('Change pinned columns', Log.FEATURE.BASIC_TABLE);

    this.setState({
      pinnedColumns: event,
    });

    LocalStorageService.setObjectAsLocalStorage(
      this.props.pinnedColumnsCookieId,
      event,
    );
  };

  storeLocally(
    columnVisibilityModel,
    columnWidthModel,
    columnOrderModel,
    rowHeight,
  ) {
    LocalStorageService.setObjectAsLocalStorage(this.props.localStorageKey, {
      columnOrderModel,
      columnVisibilityModel,
      columnWidthModel,
      rowHeight,
    });
  }

  // order the columns according to the locally stored user settings
  getOrderedColumns() {
    if (this.props.disableColumnReorder) {
      return this.props.columns;
    }

    const data = LocalStorageService.getObjectFromLocalStorage(
      this.props.localStorageKey,
    );

    if (!data?.columnOrderModel) {
      return this.props.columns;
    }

    const indexedColumns = this.props.columns.map((column, index_) => {
      // Get column index from data
      const index = data.columnOrderModel.indexOf(column.field);

      return {
        index: index === -1 ? index_ : index, // Add new columns at their specified position
        ...column,
      };
    });

    return ArrayUtils.sortByKey(indexedColumns, 'index');
  }

  passFilterModelAsProps() {
    if (!this.props.withFilterModel) {
      return null;
    }

    return {
      filterModel: this.getFilterModel(),
    };
  }

  getFilterModel() {
    const filterModel = {
      ...this.props.filterModel,
      logicOperator: this.props.filterModel?.logicOperator ?? 'and',
    };

    return filterModel;

    // The following code was implemented as a bug fix.
    // -> see ticket: https://innovent-consulting.atlassian.net/browse/VGSD-3102
    // However, it was not working with filterable: false in getColumns() of DeliveryList.js
    // -> It lead to infinite loop of rendering. Outweighing both issues, it makes more sense to live
    // with the bug of losing focus on the filter. Anyway, it makes sense to try to fix this bug as well.
    // Thus check this in the future again.
    /* if (this.props.filterModel?.items?.length > 0) return this.props.filterModel;

    // cloneDeep of DatagridUtils.EMPTY_FILTER_MODEL is necessary because otherwise the following error would be thrown:
    // Uncaught TypeError: Cannot add property 0, object is not extensible
    let filterModel = this.props.filterModel
      ? cloneDeep(this.props.filterModel)
      : cloneDeep(DatagridUtils.EMPTY_FILTER_MODEL);

    // Search for a column of type string and add an empty filter for it.
    // This is a workaround for a bug in the data grid: If no filter is set, the focus on the filter is lost when the table data refreshes.
    // Check here: https://github.com/mui/mui-x/issues/8119
    const stringColumn = this.props.columns.find((column) => !column.type || column.type === 'string');

    // Only add the empty filter if there is a column of type string because we apply the operator 'contains' which is only available for string columns.
    // There is a small chance that there is no column of type string. Then the bug of losing the focus will still exist.
    if (stringColumn) {
      filterModel.items.push({
        field: stringColumn.field,
        operator: 'contains',
        value: ''
      });
    }

    return filterModel; */
  }

  // according to cookie, columns are ordered, customized widths are set and the selected row height is applied
  getColumns() {
    const newColumns = cloneDeep(this.getOrderedColumns());

    for (const [index, column] of newColumns.entries()) {
      if (column.resizableText) {
        newColumns[index] = {
          ...newColumns[index],
          ...DatagridUtils.resizeText(this.state.rowHeight, column.renderCell), // apply the selected text size
          width:
            column.width *
            DatagridUtils.getColumnWidthFactor(this.state.rowHeight), // if no custom width is set, the default width is aligned with the row height
        };
      }

      const customWidth = this.state.columnWidthModel[column.field];
      if (customWidth) {
        newColumns[index].width = customWidth;
      }
    }

    return newColumns;
  }

  getPropsToHideHeader() {
    if (!this.props.hideHeader) {
      return null;
    }

    return {
      columnHeaderHeight: 0,
      sx: {
        '& .MuiDataGrid-columnHeaders': {
          display: 'none !important',
        },
        '& .MuiDataGrid-toolbarContainer': {
          display: 'none !important',
          visibility: 'hidden !important',
          height: '0 !important',
        },
      },
    };
  }

  getPinnedColumns() {
    if (this.state.pinnedColumns === null) {
      return (
        this.props.defaultPinnedColumns ?? {
          left: [],
          right: [],
        }
      );
    }

    return this.state.pinnedColumns;
  }

  getToolbar() {
    if (this.props.hideToolbar) {
      return null;
    }

    const {
      csvOptions,
      excelColumns,
      multiplePdfDownload,
      onExcelDlnExport,
      onExcelInvoiceExport,
      onMapDirectDeliveryNote,
      onMultiPermissionGrantEdit,
      onOpenReport,
      onPdfExport,
      onRequestDeliveryNoteSignature,
      onShareDeliveryNote,
      productAnalyticsFeature,
    } = this.props;

    const { rowHeight } = this.state;

    return (
      <GridToolbar
        csvOptions={
          csvOptions ?? {
            delimiter: ',',
            includeHeaders: true,
          }
        }
        excelColumns={excelColumns}
        excelData={this.getFilteredSortedExcelData()}
        multiplePdfDownload={multiplePdfDownload}
        onExcelDlnExport={onExcelDlnExport}
        onExcelInvoiceExport={onExcelInvoiceExport}
        onMapDirectDeliveryNote={onMapDirectDeliveryNote}
        onMultiPermissionGrantEdit={onMultiPermissionGrantEdit}
        onOpenReport={onOpenReport}
        onPdfExport={onPdfExport}
        onRequestDeliveryNoteSignature={onRequestDeliveryNoteSignature}
        onRowHeightChange={this.onRowHeightChange}
        onShareDeliveryNote={onShareDeliveryNote}
        productAnalyticsFeature={productAnalyticsFeature}
        rowHeight={rowHeight}
      />
    );
  }

  getCursor() {
    if (this.props.onRowRightClick) {
      return 'context-menu';
    }

    if (this.props.onRowClick) {
      return 'pointer';
    }

    return 'auto';
  }

  getFilteredSortedExcelData() {
    if (!this.props.excelData?.length) {
      return [];
    }

    if (!this.state.filteredSortedRows?.length) {
      return this.props.excelData;
    }

    return ArrayUtils.sortByKeyValues(
      this.props.excelData,
      this.state.filteredSortedRows,
      'id',
    );
  }

  getSlotProps() {
    const slotProps = {
      row: {
        onContextMenu: this.props.onRowRightClick,
        style: {
          cursor: this.getCursor(),
        },
      },
    };

    if (this.props.logicOperators) {
      slotProps.filterPanel = {
        logicOperators: this.props.logicOperators,
      };
    }

    return slotProps;
  }

  /**
   * Save table configuration to backend; requires write access to user!
   */
  async updateUserSettings() {
    if (UserUtils.isUserWriteAllowedUser()) {
      const userId = AuthService.getUserIdFromAccessToken();

      await promiseHandler(
        UserService.updateUserSettings(userId, {
          [this.props.localStorageKey]: {
            columnOrderModel: this.state.columnOrderModel,
            columnVisibilityModel: this.state.columnVisibilityModel,
            columnWidthModel: this.state.columnWidthModel,
            rowHeight: this.state.rowHeight,
          },
        }),
      );
    }
  }

  render() {
    let slots = {
      toolbar: () => this.getToolbar(),
      NoRowsOverlay: () => (
        <div className="flex h-full w-full items-center justify-center">
          {this.props.loading === LOADING_STATE.FAILED
            ? 'Daten konnten nicht geladen werden.'
            : 'Keine Einträge'}
        </div>
      ),
    };

    if (this.props.paginationLoading === LOADING_STATE.LOADING) {
      slots = {
        ...slots,
        Pagination: () => (
          <div className="pr-4 text-end">
            <Spinner />
          </div>
        ),
      };
    }

    if (this.props.hideFooter) {
      slots = {
        ...slots,
        footer: () => null,
      };
    }

    // Ensure detailPanelExpandedRowIds is an array of non-null values (null breaks the DataGridPro component)
    const detailPanelExpandedRowIds =
      this.state.detailPanelExpandedRowIds.filter((id) => id !== null);

    return (
      <DataGridPro
        {...this.props}
        {...this.getPropsToHideHeader()}
        {...this.passFilterModelAsProps()}
        apiRef={this.apiRef}
        columns={this.getColumns()}
        columnVisibilityModel={this.state.columnVisibilityModel}
        slots={slots}
        slotProps={this.getSlotProps()}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        disableRowSelectionOnClick
        initialState={{ pinnedColumns: this.getPinnedColumns() }}
        loading={this.props.loading === LOADING_STATE.LOADING}
        onColumnVisibilityModelChange={this.onColumnVisibilityModelChange}
        onColumnWidthChange={this.onColumnWidthChange}
        onPageChange={this.onPageChange}
        onPinnedColumnsChange={this.onPinnedColumnsChange}
        onRowSelectionModelChange={this.onRowSelectionModelChange}
        onStateChange={this.onStateChange}
        page={this.state.page}
        pageSize={this.state.pageSize}
        pagination={!this.props.infiniteScrolling}
        rowHeight={this.state.rowHeight}
      />
    );
  }
}

export default memo(BasicTable);
