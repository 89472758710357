import axios from '~/utils/api-client';
import Config from '~/Config';
import { ROUTE } from '~/constants/Route';
import RouteUtils from '~/utils/routeUtils';
import ToastService from './toast.service';
import store from '~/redux/store';
import Company from '~/models/masterdata/Company';
import Log from '~/utils/Log';
import CacheService from './cache.service';
import UserUtils from '~/utils/userUtils';
import featureService from './feature.service';
import { LOADING_STATE } from '~/constants/LoadingState';
import { replaceUser, replaceUsers, setUsersLoading } from '~/redux/usersSlice';
import SignatureRoles from '~/models/masterdata/SignatureRoles';
import ObjectUtils from '~/utils/objectUtils';
import User from '~/models/masterdata/User';
import { promiseHandler } from '~/utils/promiseHandler';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import UserFeatureFlags from '~/models/masterdata/UserFeatureFlags';

const API_URL = Config.apiUrl + '/user';

class UserService {
  constructor() {
    this.usersLoading = LOADING_STATE.NOT_LOADED;
    this.users = [];
    this.usersBulk = [];
  }

  async getData() {
    return axios
      .get(API_URL + '/data', { params: { include_company_info: true } })
      .then((response) => {
        if (response?.status === 200) {
          return response?.data;
        }

        throw response;
      });
  }

  async getAllUsers() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.users.map((item) => new User(item));
    });
  }

  getUserById = async (userId) => {
    let user = store
      .getState()
      .users?.users?.find((user) => user.id === userId);
    // For more information about why users are cached locally in UserService, check the comments in SiteService.getSiteById.
    if (
      !user &&
      store.getState().users?.usersLoading === LOADING_STATE.NOT_LOADED
    ) {
      user = this.users.find((user) => user.id === userId);
    }

    if (user) {
      return ES6ClassFactory.convertToES6Class([user], new User())[0];
    }

    if (
      store.getState().users?.usersLoading === LOADING_STATE.SUCCEEDED ||
      store.getState().users?.usersLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    if (
      this.usersLoading === LOADING_STATE.SUCCEEDED ||
      this.usersLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    store.dispatch(setUsersLoading(LOADING_STATE.LOADING));
    this.usersLoading = LOADING_STATE.LOADING;

    const [users, error] = await promiseHandler(this.getAllUsers());

    if (error) {
      store.dispatch(setUsersLoading(LOADING_STATE.FAILED));
      this.usersLoading = LOADING_STATE.FAILED;
      throw error;
    }

    store.dispatch(replaceUsers(users));
    this.usersLoading = LOADING_STATE.SUCCEEDED;
    this.users = users;

    return users.find((user) => user.id === userId) ?? null;
  };
  getUser = async (userId, ignoreCache) => {
    const url = API_URL + '/' + userId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          Log.warn('GET /user did not return 200', { status: response.status });
        }

        const user = new User(response.data);
        CacheService.setCached(url, user);
        return user;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };

  async getCompany() {
    return axios.get(API_URL + '/company').then((response) => {
      if (response?.status === 200) {
        return new Company(response?.data);
      }

      throw response;
    });
  }

  async getCompanyAccount(include_company_info, ignoreCache) {
    const url = API_URL + '/company_account';

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url, {
        params: { include_company_info },
      })
      .then((response) => {
        if (response?.status === 200) {
          CacheService.setCached(url, response.data);
          return response.data;
        }
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async createUser(body) {
    return axios.post(Config.apiUrl + '/admin/users', body).then((response) => {
      return response.data?.id;
    });
  }

  async createUsersViaCSV(file) {
    const formData = new FormData();
    formData.append('user_data', file);

    return axios
      .post(Config.apiUrl + '/admin/users_csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data.ids;
      });
  }

  async updateUser(id, body) {
    return axios.put(Config.apiUrl + '/admin/users/' + id, body);
  }

  async getUserSettings() {
    return axios.get(Config.apiUrl + '/user/settings');
  }

  async updateColorMapping(articleColorMapping) {
    return axios.post(Config.apiUrl + '/user/settings', {
      webapp_settings: { articleColorMapping },
    });
  }

  async updateDeliveryTabs(tabs) {
    return axios.post(Config.apiUrl + '/user/settings', {
      webapp_delivery_tabs: tabs,
    });
  }

  async updateDashboardFilterGroups(dashboardFilterGroups) {
    return axios.post(Config.apiUrl + '/user/settings', {
      webapp_dashboard_filter_groups: dashboardFilterGroups,
    });
  }

  async updateDeliveryFilterGroups(deliveryFilterGroups) {
    return axios.post(Config.apiUrl + '/user/settings', {
      webapp_delivery_filter_groups: deliveryFilterGroups,
    });
  }

  async updateInvoiceFilterGroups(invoiceFilterGroups) {
    return axios.post(Config.apiUrl + '/user/settings', {
      webapp_invoice_filter_groups: invoiceFilterGroups,
    });
  }

  // Bug: When user updates user actions from two different tabs, the entries will overwrite each other.
  // As this is an edge case and the bug fix would be rather complex, it is accepted as it is.
  async updateUserActions(userActions) {
    return axios.post(Config.apiUrl + '/user/settings', {
      webapp_user_actions: userActions,
    });
  }

  async getUserSignatureRoles(user_id) {
    return axios
      .get(Config.apiUrl + '/user/' + user_id + '/signature_roles')
      .then((response) => {
        if (response?.status !== 200) {
          return new SignatureRoles();
        }

        return new SignatureRoles(response.data);
      });
  }

  async getUserFeatureFlags(user_id) {
    return axios
      .get(Config.apiUrl + '/admin/user/' + user_id + '/settings')
      .then((response) => {
        if (response?.status !== 200) {
          return new UserFeatureFlags();
        }

        return new UserFeatureFlags(response.data.feature_flags);
      });
  }

  // TODO: Combine with getUserFeatureFlags to avoid two requests
  getUserDefaultSignatureRole(user_id) {
    return axios
      .get(Config.apiUrl + '/admin/user/' + user_id + '/settings')
      .then((response) => {
        if (response?.status !== 200) {
          return null;
        }

        return response.data.default_signature_role;
      });
  }

  async updateUserSignatureRoles(user_id, signatureRoles) {
    const data = {
      supplier: signatureRoles.supplier,
      carrier: signatureRoles.carrier,
      recipient: signatureRoles.recipient,
      on_behalf_supplier: signatureRoles.onBehalfSupplier,
      on_behalf_carrier: signatureRoles.onBehalfCarrier,
      on_behalf_recipient: signatureRoles.onBehalfRecipient,
    };

    return axios
      .put(Config.apiUrl + '/user/' + user_id + '/signature_roles', data)
      .then((response) => {
        if (response?.status !== 200) {
          return;
        }

        if (signatureRoles.signatureRoleIsAssigned()) {
          const descriptiveSignatureRoles = ObjectUtils.entries(data)
            .filter((entry) => entry.value)
            .map((entry) => SignatureRoles.getSignatureRole(entry.key))
            .join(', ');
          ToastService.warning([
            'Damit die Signaturberechtigungen (' +
              descriptiveSignatureRoles +
              ') wirksam werden, bitte bei der mobilen App neu einloggen.',
          ]);
        } else {
          ToastService.warning([
            'Damit das Update der Signaturberechtigung wirksam wird, bitte bei der mobilen App neu einloggen.',
          ]);
        }
      });
  }

  async updateUserSettings(userId, settings = {}) {
    return axios.post(
      `${Config.apiUrl}/admin/user/${userId}/settings`,
      settings,
    );
  }

  async updateUserFeatureFlags(user_id, featureFlags) {
    return axios.post(Config.apiUrl + '/admin/user/' + user_id + '/settings', {
      feature_flags: featureFlags,
    });
  }

  async updateUserDefaultSignatureRole(user_id, defaultSignatureRole) {
    return axios.post(Config.apiUrl + '/admin/user/' + user_id + '/settings', {
      default_signature_role: defaultSignatureRole,
    });
  }

  getUserCompany(user_id) {
    const url = Config.apiUrl + '/user/' + user_id + '/company';

    const [cachedValue, error] = CacheService.getCached(url);
    if (cachedValue) {
      return Promise.resolve(cachedValue);
    }

    if (error) {
      return Promise.reject(error);
    }

    return axios
      .get(url)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn(
            'GET user company did not return 200',
            { status_code: response?.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }

        const company = new Company(response?.data);
        CacheService.setCached(url, company);
        return company;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  getProfilePicture() {
    return axios
      .get(API_URL + '/profile_picture', {
        responseType: 'blob',
      })
      .then((response) => {
        if (response?.status === 200) {
          return response?.data;
        }

        throw response;
      });
  }

  async uploadProfilePicture(picture) {
    return axios
      .put(API_URL + '/profile_picture', picture, {
        headers: { 'Content-Type': picture.type },
      })
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn(
            'PUT profile picture did not return 200',
            { status_code: response?.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }
      });
  }

  async deleteProfilePicture() {
    return axios.delete(API_URL + '/profile_picture');
  }

  async updateUserName(first_name, last_name) {
    return axios.put(API_URL + '/name', null, {
      params: { first_name, last_name },
    });
  }

  async updateUserPosition(position = '') {
    return axios.put(API_URL + '/position', null, { params: { position } });
  }

  async updateUserPhone(phone) {
    return axios.post(API_URL + '/settings', { contact_information: phone });
  }

  userIsAuthorizedForPage(userPermissions, pageUrl) {
    // demo pageurl and company not allowed for demo and feature flag not set
    if (
      pageUrl === ROUTE.SETTINGS_DEMO.ROUTE &&
      !UserUtils.isDemoAllowedCompanyAccount(
        store?.getState()?.companyAccount?.companyAccount?.id,
      ) &&
      !featureService.createDemoDlns()
    ) {
      return false;
    }

    if (
      pageUrl === ROUTE.CONCRETE_DIARY.ROUTE &&
      !UserUtils.isConcreteDiaryAllowedUser() &&
      !store?.getState()?.userinfo?.userinfo?.userFeatureFlags?.concreteDiary
    ) {
      return false;
    }

    if (
      pageUrl === ROUTE.USER_METRICS.ROUTE &&
      !UserUtils.isUserMetricsAllowedUser() &&
      !store?.getState()?.userinfo?.userinfo?.userFeatureFlags?.userMetrics
    ) {
      return false;
    }

    if (
      RouteUtils.getRoute(pageUrl)?.ONLY_ACCESS_BY_VESTIGAS_SUPPORT &&
      !UserUtils.isVestigasAccount()
    ) {
      return false;
    }

    let authorized = false;

    const requiredPermissions = this.getRequiredPermissions(pageUrl);

    if (requiredPermissions) {
      for (const userPermission of userPermissions) {
        for (const routePermission of requiredPermissions) {
          if (userPermission.includes(routePermission)) {
            authorized = true;
          }
        }
      }
    } else {
      authorized = true;
    }

    const { disablingFeatureFlag, requiredFeatureFlag } =
      this.getFeatureFlag(pageUrl);

    if (
      requiredFeatureFlag &&
      !store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
        requiredFeatureFlag
      ]
    ) {
      return false;
    }

    if (
      disablingFeatureFlag &&
      store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
        disablingFeatureFlag
      ]
    ) {
      return false;
    }

    return authorized;
  }

  getRequiredPermissions(pageUrl) {
    const bestFittingUrl = RouteUtils.getBestFittingUrls(pageUrl);
    let requiredPermissions = [];
    for (const [index, item] of Object.keys(ROUTE).entries()) {
      if (bestFittingUrl.includes(ROUTE[item].ROUTE)) {
        requiredPermissions = ROUTE[item].PERMISSIONS;
      }
    }

    return requiredPermissions;
  }

  getFeatureFlag(pageUrl) {
    const bestFittingUrl = RouteUtils.getBestFittingUrls(pageUrl);
    const featureFlag = {};
    for (const [index, item] of Object.keys(ROUTE).entries()) {
      if (bestFittingUrl.includes(ROUTE[item].ROUTE)) {
        featureFlag.requiredFeatureFlag = ROUTE[item].FEATURE_FLAG;
        featureFlag.disablingFeatureFlag = ROUTE[item].DISABLING_FEATURE_FLAG;
      }
    }

    return featureFlag;
  }

  // return userId as fallback so that we don't display "undefined" in error toasts
  getUserEmailFromStore(userId) {
    return (
      store.getState().users.users.find((user) => user.id === userId)?.email ??
      userId
    );
  }

  async getPermittedUsersOfEntity(
    entityId,
    loadEntityCallback,
    checkEntityExistenceCallback,
  ) {
    // Check if the entity (site or cost center) is accessible by the user.
    // If not, it is also not useful to call the endpoint to load the single entity via loadEntityCallback as it would just throw an error.
    // This case happens when you are logged in as a supplier and try to access the sites or cost centers of the recipient.
    const [entityById, error] = await promiseHandler(
      checkEntityExistenceCallback(entityId),
    );
    if (error) {
      throw error;
    }

    if (!entityById) {
      return [];
    }

    const permittedUsers = [];

    const [entity, error2] = await promiseHandler(loadEntityCallback(entityId));

    if (error2) {
      throw error2;
    }

    for (let index = 0; index < entity.permissionGrantsFrom.length; index++) {
      const permissionGrantsFrom = entity.permissionGrantsFrom[index];

      if (
        permissionGrantsFrom.subjectType !==
        PermissionGrant.SUBJECT_TYPE.USER.KEY
      ) {
        continue;
      }

      const [user, error2] = await promiseHandler(
        this.getUserById(permissionGrantsFrom.subjectId),
      );

      if (error2) {
        throw error2;
      }

      if (
        user?.canReadDeliveryNotes() &&
        !permittedUsers.find((permittedUser) => permittedUser.id === user.id)
      ) {
        user.permissionGrantsOn = user.permissionGrantsOn.filter(
          (permissionGrant) => permissionGrant.entityId === entityId,
        );
        permittedUsers.push(user);
      }
    }

    return permittedUsers;
  }

  loadUsers = async () => {
    // to not load users again when they are already loading or have already been loaded
    if (store.getState().users?.usersLoading !== LOADING_STATE.NOT_LOADED) {
      return;
    }

    this.refreshUsers();
  };
  refreshUsers = async () => {
    store.dispatch(setUsersLoading(LOADING_STATE.LOADING));

    const [users, error] = await promiseHandler(this.getAllUsers());

    if (error) {
      store.dispatch(setUsersLoading(LOADING_STATE.FAILED));
      Log.error('Failed to refresh users.', error);
      Log.productAnalyticsEvent(
        'Failed to load users',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceUsers(users));
  };
  refreshUser = async (userId) => {
    const [user, error] = await promiseHandler(this.getUser(userId, true));

    if (error) {
      Log.error('Failed to refresh user. id: ' + userId, error);
      Log.productAnalyticsEvent(
        'Failed to load user',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceUser(user));
  };
  loadUsersBulk = async (ids) => {
    return axios.post(API_URL + '/query/bulk', { ids }).then((response) => {
      this.usersBulk = response.data.items.map((item) => new User(item));
    });
  };
  getUserFromUsersBulk = (userId) => {
    return this.usersBulk.find((user) => user.id === userId) ?? null;
  };
}

export default new UserService();
